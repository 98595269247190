<template>
    <div>
        <div class="right-menu shipping-right">

            <subHeader pageName="VENDORS"/>

            <tutorialsAction />

            <div class="db_top">
                <div class="db-container">

                    <moduleStats/>

                    <!-- search block starts here -->
                    <moduleSearch :moduleName="'vendor'" :disable="tourIsActive" ref="module_search"/>
                    <!-- search block ends here -->

                    <!-- data table starts here -->
                    <div class="tableMainWrapBox" style="margin-top: 25px;position: relative;" id="preview-record">
                        <div class="tableScrollWrap" ref="vendorsTable">
                            <table>
                                <thead>
                                    <tr>
                                        <th>
                                            <div class="ctmCheckWrap">
                                                <label class="ctmTableCheckContainer">
                                                    <input 
                                                        type="checkbox"
                                                        @click="toggleIsCheckedAll()"
                                                        :checked="checkedItems.length != 0 && checkedItems.length == vendorsList.length"
                                                        :disabled="tourIsActive || this.previewForm.id != ''"
                                                    >
                                                    <span class="checkmark"></span>
                                                </label>
                                                <div class="ctmTableImgWrap">
                                                    <p data-toggle="tooltip" data-placement="top" title="Image">Image</p>
                                                </div>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Name">Name</p>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Email Address">Email</p>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Contact No.">Contact No.</p>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Company">Company</p>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="City">City</p>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Action">Action</p>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody v-if="vendorsLoading">
                                    <tr v-for="i in 5" :key="i">
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="20"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                                <div class="ctmDataTableImgBox">
                                                    <vue-skeleton-loader
                                                        type="circle"
                                                        :width="36"
                                                        :height="36"
                                                        animation="fade"
                                                    />
                                                </div> 
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="200"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="200"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="200"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="200"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="200"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="100"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr v-if="vendorsList && vendorsList.length == 0">
                                        <td colspan="7" style="text-align:center">
                                            <p class="mb-0">
                                                {{ vendorSearchKeyword ? `No vendors found matching '${vendorSearchKeyword}'` : 'No Vendors Available' }}
                                            </p>
                                        </td>
                                    </tr>
                                    <tr v-for="(listedVendor, index) in vendorsList" :key="index">

                                        <!-- checkbox & image block starts here -->
                                        <td @click="previewVendor(listedVendor,index,'input',$event)">
                                            <div class="ctmCheckWrap" data-open="true">
                                                <label 
                                                    class="ctmTableCheckContainer" 
                                                    data-open="false" 
                                                    @click.prevent=" listedVendor.id != -1 ? checkedItemHandle(listedVendor.id) : '' "
                                                >
                                                    <input 
                                                        type="checkbox" 
                                                        v-model="checkedItems" 
                                                        :value="listedVendor.id" 
                                                        :disabled="tourIsActive || listedVendor.id == -1" 
                                                        data-open="false"
                                                    >
                                                    <span class="checkmark" data-open="false"></span>
                                                </label>
                                                <!-- for saved vendor -->
                                                <div class="ctmDataTableImgBox" data-open="true" v-if="listedVendor.id != -1">
                                                    <img 
                                                        :src="listedVendor.image ? listedVendor.image_src : '/images/customer_noImage.png'" 
                                                        alt=""
                                                        v-if="listedVendor.id == -1"
                                                        data-open="true"
                                                        @error="handleImageError"
                                                    >
                                                    <img
                                                        :src="generateUrl(listedVendor) ? generateUrl(listedVendor) : '/images/customer_noImage.png'"
                                                        alt=""
                                                        data-open="true"
                                                        @error="handleImageError"
                                                        v-else
                                                    >
                                                </div>
                                                <!-- for unsaved vendor -->
                                                <div class="ctmDataTableImgBox" data-open="true" v-else>
                                                    <img 
                                                        :src="listedVendor.addImage ? listedVendor.addImage_src : '/images/customer_noImage.png'" 
                                                        alt=""
                                                        v-if="listedVendor.id == -1"
                                                        data-open="true"
                                                        @error="handleImageError"
                                                    >
                                                    <img
                                                        :src="generateUrl(listedVendor) ? generateUrl(listedVendor) : '/images/customer_noImage.png'"
                                                        alt=""
                                                        data-open="true"
                                                        @error="handleImageError"
                                                        v-else
                                                    >
                                                </div>
                                            </div>
                                        </td>
                                        <!-- checkbox & image block ends here -->

                                        <!-- vendor name block starts here -->
                                        <td class="mainTableTitle" @click="previewVendor(listedVendor,index,'input',$event)">
                                            <div class="editFieldTitle" data-open="true" id="vendor-name-field-2">
                                                <input 
                                                    type="text" 
                                                    placeholder="Write a vendor name"
                                                    v-model="listedVendor.name" 
                                                    @focus="vendorSelection(listedVendor)" 
                                                    @blur="handleOTGUpdate(listedVendor,index)" 
                                                    :disabled="tourIsActive"
                                                    data-open="false"
                                                >
                                            </div>
                                        </td>
                                        <!-- vendor name block ends here -->

                                        <!-- vendor email block starts here -->
                                        <td class="mainTableTitle" @click="previewVendor(listedVendor,index,'input',$event)">
                                            <div class="editFieldTitle" data-open="true" id="vendor-email-field-2">
                                                <input 
                                                    type="text" 
                                                    placeholder="Write a vendor email"
                                                    v-model="listedVendor.email" 
                                                    @focus="vendorSelection(listedVendor)" 
                                                    @blur="handleOTGUpdate(listedVendor,index)" 
                                                    :disabled="tourIsActive"
                                                    data-open="false"
                                                >
                                            </div>
                                        </td>
                                        <!-- vendor email block ends here -->

                                        <!-- vendor contact no. block starts here -->
                                        <td @click="previewVendor(listedVendor,index,'solid',$event)">
                                            <div class="tableBodyCtmTxt" data-open="true">
                                                <p data-open="true">
                                                    {{ listedVendor.contact_no ? listedVendor.contact_no : '--' }}
                                                </p>
                                            </div>
                                        </td>
                                        <!-- vendor contact no. block ends here -->

                                        <!-- vendor company name block starts here -->
                                        <td @click="previewVendor(listedVendor,index,'solid',$event)">
                                            <div class="tableBodyCtmTxt" data-open="true">
                                                <p data-open="true">
                                                    {{ listedVendor.company_name ? listedVendor.company_name.length > 15 ? 
                                                    listedVendor.company_name.substr(0,15) + "..." : listedVendor.company_name : '--' }}
                                                </p>
                                            </div>
                                        </td>
                                        <!-- vendor company name block ends here -->

                                        <!-- vendor city block starts here -->
                                        <td @click="previewVendor(listedVendor,index,'solid',$event)">
                                            <div class="tableBodyCtmTxt" data-open="true">
                                                <p data-open="true">
                                                    {{ listedVendor.city ? listedVendor.city.length > 15 ? 
                                                    listedVendor.city.substr(0,15) + "..." : listedVendor.city : '--' }}
                                                </p>
                                            </div>
                                        </td>
                                        <!-- vendor city block ends here -->

                                        <!-- action block starts here -->
                                        <td @click.prevent="previewVendor(listedVendor,index,'input',$event)">
                                            <div class="ctmDataTableActionBtn" v-if="isDeletingItem(listedVendor.id) && deleteLoader" data-open="false">
                                                <loaderBtn />
                                            </div>
                                            <div class="ctmDataTableActionBtn" data-open="true" v-else>
                                                <button class="delFunction" @click.prevent="deleteVendorHandle(listedVendor,index)" :disabled="tourIsActive" data-open="false">
                                                    <i aria-hidden="true" class="fa fa-trash-o" data-open="false"></i>
                                                </button>
                                            </div>
                                        </td>
                                        <!-- action block ends here -->

                                    </tr>

                                    <!-- load more block starts here -->
                                    <tr v-if="isPending">
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="20"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                                <div class="ctmDataTableImgBox">
                                                    <vue-skeleton-loader
                                                        type="circle"
                                                        :width="36"
                                                        :height="36"
                                                        animation="fade"
                                                    />
                                                </div> 
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="200"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="200"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="200"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="200"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="200"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="100"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <!-- load more block ends here -->

                                </tbody>
                            </table>
                        </div>

                        <!-- vendor add/update form starts here -->
                        <div class="productSlideBox" ref="viewInputForm" :class="previewForm.id ? 'activeRow': ''" >
                            <div class="tableActionBtns" id="vendor-details-preview">
                                <div class="productTableSideBar">
                                    <div class="row">
                                        <div class="col-md-8" id="vendor-name-field-3">
                                            <div class="viewOrderTitle">
                                                <input 
                                                    type="text" 
                                                    placeholder="Write a vendor name"
                                                    v-model="previewForm.name"
                                                    :disabled="tourIsActive"
                                                >
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="orderViewNav">
                                                <ul>
                                                    <el-tooltip class="box-item" effect="dark" content="Expand" placement="top-start">
                                                        <li class="sm-extand-hide">
                                                            <button @click.prevent="expand" :disabled="tourIsActive" id="expand-form">
                                                                <svg viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
                                                                    <g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" transform="translate(2 2)">
                                                                        <path d="m16.5 5.5v-4.978l-5.5.014"/>
                                                                        <path d="m16.5.522-6 5.907"/>
                                                                        <path d="m11 16.521 5.5.002-.013-5.5"/>
                                                                        <path d="m16.5 16.429-6-5.907"/>
                                                                        <path d="m.5 5.5v-5h5.5"/>
                                                                        <path d="m6.5 6.429-6-5.907"/>
                                                                        <path d="m6 16.516-5.5.007v-5.023"/>
                                                                        <path d="m6.5 10.5-6 6"/>
                                                                    </g>
                                                                </svg>
                                                            </button>
                                                        </li>
                                                    </el-tooltip>
                                                    <el-tooltip class="box-item" effect="dark" content="Close" placement="top-start">
                                                        <li>
                                                            <button @click.prevent="closePreview" id="closeSide" :disabled="tourIsActive">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="29.446" height="33.409" viewBox="0 0 29.446 33.409">
                                                                    <g id="Group_20906" data-name="Group 20906" transform="translate(362.041 -2185.5)">
                                                                        <path id="Path_57522" data-name="Path 57522" d="M-361.041,2186.648v31.409" transform="translate(0 -0.148)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="2"/>
                                                                        <g id="Group_20905" data-name="Group 20905" transform="translate(0 -0.5)">
                                                                            <path id="Path_57523" data-name="Path 57523" d="M-354.018,2202.5h20.265" transform="translate(0 0)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="2"/>
                                                                            <path id="Path_57524" data-name="Path 57524" d="M-342.6,2193.747l8,9.15-8,8.85" transform="translate(1 -0.247)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                                                        </g>
                                                                    </g>
                                                                </svg>
                                                            </button>
                                                        </li>
                                                    </el-tooltip>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="sectionTabsLink">
                                    <ul onclick="window.myFunction(event)">
                                        <li v-if="!tourIsActive">
                                            <a class="active">Vendor Info</a>
                                        </li>
                                    </ul>
                                    <div class="updateBtnBox" v-if="vendorIsSaving">
                                        <loaderBtn/>
                                    </div>
                                    <div class="updateBtnBox" id="save-vendor-btn" v-else>
                                        <button 
                                            form="vendor-information" 
                                            type="submit"
                                            :disabled="tourIsActive"
                                        >
                                            {{ previewForm.id == -1 ? 'Save' : 'Update' }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <ValidationObserver ref="vendorFormObserver">
                                <div class="productTableInfoBlock">
                                    <div class="productTableInfoWhiteBox">

                                        <!-- image for add vendor -->
                                        <div class="uploadNewUserImg" id="vendor-image" v-if="this.previewForm.id == -1">
                                            <div class="userDetailsImg">
                                                <img 
                                                    :src="previewForm.addImage ? previewForm.addImage_src ? previewForm.addImage_src : base_image_url + setting.store_id + '/vendor/' + previewForm.addImage : '/images/customer_noImage.png' " 
                                                    alt=""
                                                    @error="handleImageError"
                                                />
                                            </div>
                                            <div class="uploadImgFile" id="add-vendor-image">
                                                <input 
                                                    type="file" 
                                                    ref="addPreviewFileInput" 
                                                    @change.prevent="changeAddImage" 
                                                    accept="image/png,image/jpg,image/jpeg,image/webp" 
                                                    :disabled="tourIsActive"
                                                >
                                                <button>
                                                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M20.9984 6.24971C20.9984 4.45495 19.5435 3 17.7487 3H6.24971C4.45495 3 3 4.45495 3 6.24971V17.7487C3 19.5435 4.45495 20.9984 6.24971 20.9984H11.1644L11.5202 19.5754C11.5266 19.5497 11.5333 19.5241 11.5402 19.4985H6.24971C6.04586 19.4985 5.85015 19.4637 5.66822 19.3996L11.4745 13.7138L11.558 13.6431C11.8493 13.4307 12.2588 13.4543 12.5238 13.7139L14.6028 15.7501L15.6636 14.6893L13.5732 12.6423L13.4448 12.5257C12.5666 11.7877 11.2581 11.8265 10.4251 12.6421L4.60312 18.3423C4.53629 18.157 4.49987 17.9571 4.49987 17.7487V6.24971C4.49987 5.2833 5.2833 4.49987 6.24971 4.49987H17.7487C18.7151 4.49987 19.4985 5.2833 19.4985 6.24971V11.2317C19.9774 11.0412 20.492 10.9678 20.9984 11.0115V6.24971ZM17.503 8.75161C17.503 7.50791 16.4947 6.49969 15.251 6.49969C14.0073 6.49969 12.9991 7.50791 12.9991 8.75161C12.9991 9.99531 14.0073 11.0035 15.251 11.0035C16.4947 11.0035 17.503 9.99531 17.503 8.75161ZM14.499 8.75161C14.499 8.33626 14.8357 7.99956 15.251 7.99956C15.6664 7.99956 16.0031 8.33626 16.0031 8.75161C16.0031 9.16695 15.6664 9.50366 15.251 9.50366C14.8357 9.50366 14.499 9.16695 14.499 8.75161ZM19.0984 12.6686L13.1965 18.5705C12.8524 18.9146 12.6083 19.3458 12.4903 19.8179L12.0327 21.6484C11.8336 22.4445 12.5547 23.1656 13.3508 22.9666L15.1813 22.5089C15.6534 22.3909 16.0846 22.1468 16.4287 21.8027L22.3306 15.9008C23.2231 15.0082 23.2231 13.5611 22.3306 12.6686C21.4381 11.7761 19.991 11.7761 19.0984 12.6686Z"/>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                        <span class="text-danger mx-auto" style="text-align:center; display:block" v-if="this.previewForm.id == -1 && imageValidations.imgIsRequired">Image field is required</span>
                                        
                                        <!-- image for update vendor -->
                                        <div class="uploadNewUserImg" id="vendor-image" v-if="this.previewForm.id != -1">
                                            <div class="userDetailsImg">
                                                <img 
                                                    :src="previewForm.image ? previewForm.image_src ? previewForm.image_src : base_image_url + settings.store_id + '/vendor/' + previewForm.image : '/images/customer_noImage.png' " 
                                                    alt=""
                                                    @error="handleImageError"
                                                />
                                            </div>
                                            <div class="uploadImgFile">
                                                <input 
                                                    type="file" 
                                                    ref="previewFileInput" 
                                                    @change.prevent="handleChangeEditImage" 
                                                    accept="image/png,image/jpg,image/jpeg,image/webp"
                                                    :disabled="tourIsActive"
                                                >
                                                <button>
                                                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M20.9984 6.24971C20.9984 4.45495 19.5435 3 17.7487 3H6.24971C4.45495 3 3 4.45495 3 6.24971V17.7487C3 19.5435 4.45495 20.9984 6.24971 20.9984H11.1644L11.5202 19.5754C11.5266 19.5497 11.5333 19.5241 11.5402 19.4985H6.24971C6.04586 19.4985 5.85015 19.4637 5.66822 19.3996L11.4745 13.7138L11.558 13.6431C11.8493 13.4307 12.2588 13.4543 12.5238 13.7139L14.6028 15.7501L15.6636 14.6893L13.5732 12.6423L13.4448 12.5257C12.5666 11.7877 11.2581 11.8265 10.4251 12.6421L4.60312 18.3423C4.53629 18.157 4.49987 17.9571 4.49987 17.7487V6.24971C4.49987 5.2833 5.2833 4.49987 6.24971 4.49987H17.7487C18.7151 4.49987 19.4985 5.2833 19.4985 6.24971V11.2317C19.9774 11.0412 20.492 10.9678 20.9984 11.0115V6.24971ZM17.503 8.75161C17.503 7.50791 16.4947 6.49969 15.251 6.49969C14.0073 6.49969 12.9991 7.50791 12.9991 8.75161C12.9991 9.99531 14.0073 11.0035 15.251 11.0035C16.4947 11.0035 17.503 9.99531 17.503 8.75161ZM14.499 8.75161C14.499 8.33626 14.8357 7.99956 15.251 7.99956C15.6664 7.99956 16.0031 8.33626 16.0031 8.75161C16.0031 9.16695 15.6664 9.50366 15.251 9.50366C14.8357 9.50366 14.499 9.16695 14.499 8.75161ZM19.0984 12.6686L13.1965 18.5705C12.8524 18.9146 12.6083 19.3458 12.4903 19.8179L12.0327 21.6484C11.8336 22.4445 12.5547 23.1656 13.3508 22.9666L15.1813 22.5089C15.6534 22.3909 16.0846 22.1468 16.4287 21.8027L22.3306 15.9008C23.2231 15.0082 23.2231 13.5611 22.3306 12.6686C21.4381 11.7761 19.991 11.7761 19.0984 12.6686Z"/>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                        <span class="text-danger mx-auto" style="text-align:center; display:block" v-if="this.previewForm.id != -1 && imageValidations.imgIsRequired">Image field is required</span>

                                        <form id="vendor-information" @submit.prevent="validate()">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <ValidationProvider name="name" :rules="{required:true,max:250}" v-slot="{ errors }">
                                                        <div class="tableFromFieldBox">
                                                            <label for="vendor-name-field-1">Name<span>*</span></label>
                                                            <div class="tableFromFieldItem">
                                                                <input 
                                                                    type="text" 
                                                                    name="vendor-name-field-1" 
                                                                    id="vendor-name-field-1" 
                                                                    placeholder="Name" 
                                                                    v-model="previewForm.name" 
                                                                    :disabled="tourIsActive"
                                                                >
                                                                <span class="text-danger" >{{ errors[0] }}</span>
                                                            </div>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-md-6">
                                                    <ValidationProvider name="email" rules="required|email|max:250" v-slot="{ errors }">
                                                        <div class="tableFromFieldBox">
                                                            <label for="vendor-email-field">Email<span>*</span></label>
                                                            <div class="tableFromFieldItem">
                                                                <input 
                                                                    type="email" 
                                                                    name="vendor-email-field" 
                                                                    id="vendor-email-field" 
                                                                    placeholder="Email" 
                                                                    v-model="previewForm.email"
                                                                    :disabled="tourIsActive"
                                                                >
                                                                <span class="text-danger" >{{ errors[0] }}</span>
                                                            </div>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-md-6">
                                                    <ValidationProvider name="company name" :rules="{required:true,max:250}" v-slot="{ errors }">
                                                        <div class="tableFromFieldBox">
                                                            <label for="vendor-company-name-field-1">Company<span>*</span></label>
                                                            <div class="tableFromFieldItem">
                                                                <input 
                                                                    type="text" 
                                                                    name="vendor-company-name-field-1" 
                                                                    id="vendor-company-name-field-1" 
                                                                    placeholder="Company name" 
                                                                    v-model="previewForm.company_name" 
                                                                    :disabled="tourIsActive"
                                                                >
                                                                <span class="text-danger" >{{ errors[0] }}</span>
                                                            </div>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-md-6">
                                                    <ValidationProvider name="city" :rules="{required:true,max:250}" v-slot="{ errors }">
                                                        <div class="tableFromFieldBox">
                                                            <label for="vendor-city-name-field-1">City<span>*</span></label>
                                                            <div class="tableFromFieldItem">
                                                                <input 
                                                                    type="text" 
                                                                    name="vendor-city-name-field-1" 
                                                                    id="vendor-city-name-field-1" 
                                                                    placeholder="City" 
                                                                    v-model="previewForm.city" 
                                                                    :disabled="tourIsActive"
                                                                >
                                                                <span class="text-danger" >{{ errors[0] }}</span>
                                                            </div>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-md-12" id="contact-field">
                                                    <div class="tableFromFieldBox">
                                                        <label for="vendor-contact-no-field">Mobile No.<span>*</span></label>
                                                        <div class="tableFromFieldItem">
                                                            <contactInput
                                                                :contactNo="previewForm.contact_no"
                                                                :placeHolder="'Mobile no.'"
                                                                :setValidations="setMobileNoFieldValidations"
                                                                :disabled="tourIsActive"
                                                                ref="contactInputfield"
                                                            />
                                                            <span class="text-danger" v-if="!countryIsSelected && contactFieldTouched">
                                                                Select a country from the dropdown menu or add the country code before the mobile no.
                                                            </span>
                                                            <span class="text-danger" v-else-if="!contactIsValid && contactFieldTouched">Enter a valid mobile no.</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <ValidationProvider name="address" rules="required|max:500" v-slot="{ errors }">
                                                        <div class="tableFromFieldBox">
                                                            <label for="vendor-address-field">Address<span>*</span></label>
                                                            <div class="tableFromFieldItem">
                                                                <textarea 
                                                                    name="vendor-address-field" 
                                                                    id="vendor-address-field" 
                                                                    placeholder="Address" 
                                                                    v-model="previewForm.address"
                                                                    :disabled="tourIsActive"
                                                                >
                                                                </textarea>
                                                                <span class="text-danger" >{{ errors[0] }}</span>
                                                            </div>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </ValidationObserver>
                        </div>
                        <!-- vendor add/update form ends here -->

                    </div>
                    <!-- data table ends here -->

                    <!-- bottom action bar starts here -->
                    <div class="row product-bottom-bar m-0" v-if="checkedItems.length > 1">
                        <div class="col-md-6 model-footer-main1">
                            <div class="bottom-bar">
                                <a href="" class="model-footer-1 mr-5" @click.prevent="toggleIsCheckedAll()" v-if="checkedItems.length != vendorsList.length">
                                    <i class="fa fa-check-square-o" aria-hidden="true"></i>
                                    <p>SELECT ALL</p>
                                </a>
                                <a href="" class="model-footer-1" @click.prevent="clearSelection()">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                                        <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
                                    </svg>
                                    <p>CLEAR SELECTION</p>
                                </a>
                            </div>
                            <div class="model-footer-2">{{ checkedItems.length }}</div>
                        </div>
                        <div class="col-md-6 model-footer-main1 product-row-reverse">
                            <div class="bottom-bar">
                                <a href="" class="model-footer-1 ml-4" @click.prevent="vendorsBulkActionHandle('delete')">
                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                    <p>DELETE</p>
                                </a>
                            </div>
                        </div>
                    </div>
                    <!-- bottom action bar ends here -->

                </div>
            </div>

        </div>

        <!-- vue tour -->
        <v-tour name="vendorsTour" :steps="tourSteps" :options="tourOptions" :callbacks="tourCallbacks"></v-tour>

    </div>
</template>

<script>
import { MessageBox, Message, Loading } from 'element-ui'
import _ from 'lodash';
import { mapGetters } from 'vuex';
import subHeader from "@/components/subHeader.vue";
import moduleStats from "@/components/moduleStats.vue";
import contactInput from "@/components/contactInput.vue";
import tutorialsAction from "@/components/tutorialsAction.vue";
import loaderBtn from "@/components/loaderBtn.vue";
import moduleSearch from "@/components/ModuleSearch/index.vue";

export default {
    metaInfo() {
        return {
            title: 'Expenses/Purchases | Vendors | YeetCommerce',
        };
    },
    data(){
        return{
            base_image_url: process.env.VUE_APP_STORE_IMAGE_BASE_URL,

            //search start
            searchedItem:'',
            //search end

            //vendors start
            vendorsList:[],
            vendorsLoading:false,
            vendorIsSaving:false,
            vendorSaved:false,
            previewForm:{
                id:'',
                name:'',
                email:'',
                contact_no:'',
                company_name:'',
                address:'',
                city:'',
                image: null,
                image_src: '',
                addImage:null,
                addImage_src:'',
                isTouched:false,
            },
            defaultPreviewForm:{
                id:'',
                name:'',
                email:'',
                contact_no:'',
                company_name:'',
                address:'',
                city:'',
                image: null,
                image_src: '',
                addImage:null,
                addImage_src:'',
                isTouched:false,
            },
            tempVendor:{
                id:'',
                name:'',
                email:'',
                contact_no:'',
                company_name:'',
                address:'',
                city:'',
                image: null,
                image_src: '',
                addImage:null,
                addImage_src:'',
                isTouched:false,
            },
            tempPreviewVendor:{
                id:'',
                name:'',
                email:'',
                contact_no:'',
                company_name:'',
                address:'',
                city:'',
                image: null,
                image_src: '',
                addImage:null,
                addImage_src:'',
                isTouched:false,
            },
            //vendors end

            //image validations start
            imageValidations:{
                imgIsRequired:false,
                imgInvalidFormat:false,
                isNotImg:false,
            },
            //image validations end

            //mobile no. properties start
            contactIsValid:true,
            countryIsSelected:false,
            contactFieldTouched:false,
            selectedContactObj:{},
            selectedContactCountry:[],
            //mobile no. properties end

            //checked items start
            checkedItems:[],
            deletingItems:[],
            deleteLoader:false,
            isMasterSearched:false,
            //checked items end

            //tour start
            tourOptions: {
                useKeyboardNavigation: false,
                labels: {
                    buttonSkip: 'Skip Tour',
                    buttonPrevious: 'Previous',
                    buttonNext: 'Next',
                    buttonStop: 'Finish Tour'
                }
            },
            tourCallbacks: {
                onPreviousStep: this.handlePreviousStep,
                onNextStep: this.handleNextStep,
                // onStart: this.handleTourStart,
                onStop: this.handleTourStop,
                onFinish: this.handleTourFinish,
                onSkip: this.handleTourSkip
            },
            tourSteps:[
                //header steps
                {
                    target: '#tour-start-btn',
                    content: `<strong>Start Tour</strong><br>Provides insights into the available functionalities within a specific menu, helping you understand what actions you can perform.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#master-search',
                    content: `<strong>Master Search</strong><br>Enables you to perform searches from all over the dashboard.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    },
                },
                {
                    target: '#add-new-dropDown',
                    content: `<strong>Add New Record</strong><br>Enables you to add new orders, products and categories on the go.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#help-link',
                    content: `<strong>Help</strong><br>If you require any assistance, feel free to reach out to us.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#user-options-dropdown',
                    content: `<strong>User Information</strong><br>Presents essential details about you along with a selection of available options.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                //vendors steps
                {
                    target: '#module-search',
                    content: `<strong>Search Vendor</strong><br>It enables you to search specific vendors.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#preview-record',
                    content: `<strong>Vendors Table</strong><br>You can preview and edit the added vendors by clicking on each individual record present in the table.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#add-new-record',
                    content: `Add a <strong>Vendor</strong><br>By clicking this button, you can add a new vendor and open a form to input and save the details.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#vendor-details-preview',
                    content: `<strong>Vendor Details Form</strong><br>This form can be used to add details about the vendor and save them.`,
                    params: {
                        highlight: false,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#add-vendor-image',
                    content: `<strong>Vendor Image</strong><br>Image of the vendor can be added using this option.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'right'
                    }
                },
                {
                    target: '#vendor-name-field-1',
                    content: `<strong>Name Field</strong><br>name of the vendor can be added here.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'right'
                    }
                },
                {
                    target: '#vendor-email-field',
                    content: `<strong>Email Field</strong><br>email of the vendor can be added here.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'right'
                    }
                },
                {
                    target: '#vendor-company-name-field-1',
                    content: `<strong>Company Name Field</strong><br>company name of the vendor can be added here.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'right'
                    }
                },
                {
                    target: '#vendor-city-name-field-1',
                    content: `<strong>City Name Field</strong><br>city name of the vendor can be added here.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'right'
                    }
                },
                {
                    target: '#contact-field',
                    content: `<strong>Contact Field</strong><br>contact no. of the vendor can be added here.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'right'
                    }
                },
                {
                    target: '#vendor-address-field',
                    content: `<strong>Address Field</strong><br>address of the vendor can be added here.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'right'
                    }
                },
                {
                    target: '#vendor-name-field-2',
                    content: `<strong>Name Field</strong><br>name of the vendor can also be added using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'right'
                    }
                },
                {
                    target: '#vendor-name-field-3',
                    content: `<strong>Name Field</strong><br>name of the vendor can also be added using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'right'
                    }
                },
                {
                    target: '#vendor-email-field-2',
                    content: `<strong>Email Field</strong><br>email of the vendor can also be added using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'right'
                    }
                },
                {
                    target: '#expand-form',
                    content: `<strong>Expand Form</strong><br>Form can be expanded or collapsed by clicking this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'left'
                    }
                },
                {
                    target: '#closeSide',
                    content: `<strong>Close Form</strong><br>Form can be closed using this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'left'
                    }
                },
                {
                    target: '#save-vendor-btn',
                    content: `<strong>Save Vendor Details</strong><br>Added vendor details can be saved by clicking this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'left'
                    }
                },
            ],
            tourIsActive:false,
            //tour end

        }
    },
    components:{
        subHeader,
        contactInput,
        tutorialsAction,
        loaderBtn,
        moduleStats,
        moduleSearch,
    },
    computed:{
        ...mapGetters({
            isPending: 'vendor_module/isPending',
            storeVendors: 'vendor_module/vendors',
            vendorsCurrentPage: 'vendor_module/currentPage',
            vendorsLastPage: 'vendor_module/lastPage',
            vendorSearchKeyword:'vendor_module/keyword',
            settings:'settings_module/settings',
            storeAllCountries: 'countries_module/allCountries'
        })
    },
    watch:{
        'storeVendors':{
            handler:function(value){

                this.vendorsLoading = false;

                if(this.previewForm.id == -1){
                    
                    this.vendorsList = _.cloneDeep(value)

                    this.vendorsList.unshift(this.previewForm)

                }else if(this.tempPreviewVendor.id != ''){
                    
                    this.vendorsList = _.cloneDeep(value)

                    this.vendorsList[this.previewForm.index] = this.previewForm

                }else{

                    this.vendorsList = _.cloneDeep(value)

                }

                if(this.isMasterSearched){

                    this.previewSearchedVendor(this.vendorsList[0],0)

                    this.isMasterSearched = false
                }

            },deep:true
        },
        'previewForm':{
            handler:function(value){

                if((value.id == -1) && (value.name || value.email || value.company_name || 
                value.city || value.contact_no || value.address || value.addImage)){

                    value.isTouched = true;

                }else{

                    value.isTouched = false;

                }


            },deep:true
        },
    },
    methods:{
        //infinite scroll start
        handleScrollEvent(event){

            let element = this.$refs.vendorsTable;

            let maxScrollTop = element.scrollHeight - element.clientHeight;

            if(element.scrollTop >= maxScrollTop - 100){

                if(this.isPending == false && this.vendorsCurrentPage != this.vendorsLastPage){

                    this.$store.dispatch('vendor_module/fetchStoreVendorsRequest',{size:10, page: this.vendorsCurrentPage + 1, loadData: 'more'});

                }

            }

        },
        //infinite scroll end

        //OTG update start
        vendorSelection(listedVendor){

            this.tempVendor = _.cloneDeep(listedVendor);

        },
        async handleOTGUpdate(listedVendor,index){

            if(this.previewForm.id == ''){

                if(listedVendor.id != -1){

                    if((this.tempVendor.name != listedVendor.name) || 
                    (this.tempVendor.email != listedVendor.email)){

                        if(listedVendor.name.trim().length == 0){

                            this.$notify({
                                type: 'warning',
                                title: 'Alert',
                                message: "The vendor name field is required.",
                            });

                            this.vendorsList[index].name = this.tempVendor.name;

                            return

                        }

                        if(listedVendor.name.trim().length > 250){

                            this.$notify({
                                type: 'warning',
                                title: 'Alert',
                                message: "The vendor name may not be greater than 250 characters.",
                            });

                            this.vendorsList[index].name = this.tempVendor.name;
                            
                            return

                        }

                        if(listedVendor.email.trim().length == 0){

                            this.$notify({
                                type: 'warning',
                                title: 'Alert',
                                message: "The vendor email field is required.",
                            });

                            this.vendorsList[index].email = this.tempVendor.email;

                            return

                        }

                        if(listedVendor.email.trim().length > 250){

                            this.$notify({
                                type: 'warning',
                                title: 'Alert',
                                message: "The vendor email may not be greater than 250 characters.",
                            });

                            this.vendorsList[index].email = this.tempVendor.email;

                            return

                        }

                        let regex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{3,}))$/);

                        let isValid = regex.test(listedVendor.email)

                        if(listedVendor.email && !isValid){

                            this.$notify({
                                type: 'warning',
                                title: 'Alert',
                                message: "The vendor email field must be a valid email.",
                            })

                            this.vendorsList[index].email = this.tempVendor.email;

                            return

                        }

                        MessageBox.confirm(
                            'You have unsaved changes, save and proceed?',
                            'Confirm',
                            {
                                distinguishCancelAndClose: true,
                                confirmButtonText: 'Save',
                                cancelButtonText: 'Discard Changes',
                            }
                        ).then(async () => {

                            let formData = new FormData();

                            formData.append('id',listedVendor.id);
                            formData.append('company_name',listedVendor.company_name);
                            formData.append('name',listedVendor.name.trim());
                            formData.append('email',listedVendor.email.trim());
                            formData.append('contact_no',listedVendor.contact_no);
                            formData.append('address',listedVendor.address);
                            formData.append('city',listedVendor.city);

                            let loader = Loading.service({
                                text: "The vendor is being updated. Please wait!",
                                fullscreen: true,
                            });

                            try{
                                let res = await this.$axios.post('/vendor/update',formData);
                                if(res.data.status_code == "1048"){

                                    this.$notify({
                                        type: 'success',
                                        title: 'Success',
                                        message: res.data.message,
                                    });

                                    let payload = {
                                        vendor: res.data.vendor,
                                        index:  index
                                    }

                                    this.$store.commit('vendor_module/update_vendors_list',payload);

                                    this.$store.commit('gallery_module/CLEAR_MODULE');

                                    this.$store.commit('expenses_module/CLEAR_MODULE');

                                    this.$store.commit('bills_module/CLEAR_MODULE');

                                    this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

                                }
                            }catch(error){
                                
                                this.vendorsList[index].name = this.tempVendor.name;
                                this.vendorsList[index].email = this.tempVendor.email;

                                this.tempVendor = null;

                                if(error.response){

                                    if(error.response.data.error.id){

                                        this.$message({
                                            type: 'error',
                                            showClose: true,
                                            message: error.response.data.error.id[0],
                                        });

                                    }else if(error.response.data.error.email){

                                        this.$message({
                                            type: 'error',
                                            showClose: true,
                                            message: error.response.data.error.email[0],
                                        });

                                    }else if(error.response.data.error.includes('storage limit has been reached')){

                                        this.$message({
                                            type: 'error',
                                            showClose: true,
                                            dangerouslyUseHTMLString: true,
                                            message: error.response.data.error,
                                        });

                                    }else if(error.response.data.error.includes("doesn't support")){

                                        let subscriptionPath = window.location.origin + "/subscription";

                                        this.$message({
                                            type: 'error',
                                            showClose: true,
                                            dangerouslyUseHTMLString: true,
                                            message: error.response.data.error 
                                            + ` <a href='${subscriptionPath}' target='_blank'>Click here</a> to Upgrade your plan.`,
                                        });

                                    }else{

                                        this.$message({
                                            type: 'error',
                                            showClose: true,
                                            message: error.response.data.message,
                                        });

                                    }

                                }else{

                                    this.$message({
                                        type: 'error',
                                        showClose: true,
                                        message: error.message,
                                    });

                                }

                            }finally{

                                loader.close();

                            }

                        }).catch(() => {

                            this.vendorsList[index].name = this.tempVendor.name;
                            this.vendorsList[index].email = this.tempVendor.email;

                            this.$notify({
                                type: "info",
                                title: "Changes Discarded",
                                message: "Changes discarded successfully.",
                            });

                            this.tempVendor = null;

                        })

                    }

                }

            }else{

                if(listedVendor.id != -1){
                
                    if(listedVendor.id != this.previewForm.id){

                        if((this.tempVendor.name != listedVendor.name) || 
                        (this.tempVendor.email != listedVendor.email)){

                            if(listedVendor.name.trim().length == 0){

                                this.$notify({
                                    type: 'warning',
                                    title: 'Alert',
                                    message: "The vendor name field is required.",
                                });

                                this.vendorsList[index].name = this.tempVendor.name;

                                return

                            }

                            if(listedVendor.name.trim().length > 250){

                                this.$notify({
                                    type: 'warning',
                                    title: 'Alert',
                                    message: "The vendor name may not be greater than 250 characters.",
                                });

                                this.vendorsList[index].name = this.tempVendor.name;

                                return

                            }

                            if(listedVendor.email.trim().length == 0){

                                this.$notify({
                                    type: 'warning',
                                    title: 'Alert',
                                    message: "The vendor email field is required.",
                                });

                                this.vendorsList[index].email = this.tempVendor.email;

                                return

                            }

                            if(listedVendor.email.trim().length > 250){

                                this.$notify({
                                    type: 'warning',
                                    title: 'Alert',
                                    message: "The vendor email may not be greater than 250 characters.",
                                });

                                this.vendorsList[index].email = this.tempVendor.email;

                                return

                            }

                            let regex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{3,}))$/);

                            let isValid = regex.test(listedVendor.email)

                            if(listedVendor.email && !isValid){

                                this.$notify({
                                    type: 'warning',
                                    title: 'Alert',
                                    message: "The vendor email field must be a valid email.",
                                })

                                this.vendorsList[index].email = this.tempVendor.email;

                                return

                            }

                            MessageBox.confirm(
                                'You have unsaved changes, save and proceed?',
                                'Confirm',
                                {
                                    distinguishCancelAndClose: true,
                                    confirmButtonText: 'Save',
                                    cancelButtonText: 'Discard Changes',
                                }
                            ).then(async () => {

                                let formData = new FormData();

                                formData.append('id',listedVendor.id);
                                formData.append('company_name',listedVendor.company_name);
                                formData.append('name',listedVendor.name.trim());
                                formData.append('email',listedVendor.email.trim());
                                formData.append('contact_no',listedVendor.contact_no);
                                formData.append('address',listedVendor.address);
                                formData.append('city',listedVendor.city);

                                let loader = Loading.service({
                                    text: "The vendor is being updated. Please wait!",
                                    fullscreen: true,
                                });

                                try{
                                    let res = await this.$axios.post('/vendor/update',formData);
                                    if(res.data.status_code == "1048"){

                                        this.$notify({
                                            type: 'success',
                                            title: 'Success',
                                            message: res.data.message,
                                        });

                                        let payload = {
                                            vendor: res.data.vendor,
                                            index:  index
                                        }

                                        this.$store.commit('vendor_module/update_vendors_list',payload);

                                        this.$store.commit('gallery_module/CLEAR_MODULE');

                                        this.$store.commit('expenses_module/CLEAR_MODULE');

                                        this.$store.commit('bills_module/CLEAR_MODULE');

                                        this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

                                    }
                                }catch(error){

                                    this.vendorsList[index].name = this.tempVendor.name;
                                    this.vendorsList[index].email = this.tempVendor.email;

                                    this.tempVendor = null;

                                    if(error.response){

                                        if(error.response.data.error.id){

                                            this.$message({
                                                type: 'error',
                                                showClose: true,
                                                message: error.response.data.error.id[0],
                                            });

                                        }else if(error.response.data.error.email){

                                            this.$message({
                                                type: 'error',
                                                showClose: true,
                                                message: error.response.data.error.email[0],
                                            });

                                        }else if(error.response.data.error.includes('storage limit has been reached')){

                                            this.$message({
                                                type: 'error',
                                                showClose: true,
                                                dangerouslyUseHTMLString: true,
                                                message: error.response.data.error,
                                            });

                                        }else if(error.response.data.error.includes("doesn't support")){

                                            let subscriptionPath = window.location.origin + "/subscription";

                                            this.$message({
                                                type: 'error',
                                                showClose: true,
                                                dangerouslyUseHTMLString: true,
                                                message: error.response.data.error 
                                                + ` <a href='${subscriptionPath}' target='_blank'>Click here</a> to Upgrade your plan.`,
                                            });

                                        }else{

                                            this.$message({
                                                type: 'error',
                                                showClose: true,
                                                message: error.response.data.message,
                                            });

                                        }

                                    }else{

                                        this.$message({
                                            type: 'error',
                                            showClose: true,
                                            message: error.message,
                                        });

                                    }

                                }finally{

                                    loader.close();

                                }

                            }).catch(() => {

                                this.vendorsList[index].name = this.tempVendor.name;
                                this.vendorsList[index].email = this.tempVendor.email;

                                this.$notify({
                                    type: "info",
                                    title: "Changes Discarded",
                                    message: "Changes discarded successfully.",
                                });

                                this.tempVendor = null;

                            })

                        }

                    }
                
                }

            }

        },
        //OTG update end

        //preview form open/close & reset start
        previewVendor(listedVendor,i,field,e){

            if(field == 'input'){

                if((e.target.tagName == "TD" || e.target.dataset.open == "true") && !this.tourIsActive){

                    if(listedVendor.id != -1){

                        if(this.previewForm.id == -1){

                            MessageBox.confirm(
                                "Are you sure? unsaved changes will be discarded", 
                                "Warning", 
                                {
                                    confirmButtonText: "Yes",
                                    cancelButtonText: "Cancel",
                                    type: "warning",
                                }
                            ).then(()=>{

                                let index = this.vendorsList.findIndex((vendor) => vendor.id == -1);

                                if(index != -1){

                                    this.vendorsList.splice(index,1); 

                                }

                                if(!this.vendorSaved){

                                    let tempIndex = this.vendorsList.findIndex(x=>x.id == this.tempPreviewVendor.id);

                                    if(tempIndex != -1){

                                        this.vendorsList[tempIndex] = this.tempPreviewVendor

                                    }

                                }

                                this.resetImageValidations();

                                if(this.$refs.previewFileInput){

                                    this.$refs.previewFileInput.value = ''

                                }

                                let vendor = listedVendor

                                this.previewForm = vendor
                                this.previewForm.index = i

                                this.previewForm.image_src = null
                                this.previewForm.image = vendor.image

                                this.checkedItems = [];

                                this.vendorIsSaving = false;

                                this.tempPreviewVendor = _.cloneDeep(vendor)

                            }).catch(() => {})

                        }else{

                            let index = this.vendorsList.findIndex((vendor) => vendor.id == -1);
                                
                            if(index != -1){
                
                                this.vendorsList.splice(index, 1);
                
                            }

                            if(!this.vendorSaved && (this.tempPreviewVendor.id != '' && this.tempPreviewVendor.id != listedVendor.id)){
                                
                                let tempIndex = this.vendorsList.findIndex(x=>x.id == this.tempPreviewVendor.id);
                                
                                if(tempIndex != -1){

                                    this.vendorsList[tempIndex] = this.tempPreviewVendor;

                                }
                
                            }

                            if(this.tempPreviewVendor.id != '' && this.tempPreviewVendor.id == listedVendor.id){

                                let tempIndex = this.vendorsList.findIndex(x=>x.id == this.tempPreviewVendor.id);
                                    
                                this.vendorsList[tempIndex] = _.cloneDeep(this.storeVendors[tempIndex]);

                                this.resetImageValidations();

                                if(this.$refs.previewFileInput){

                                    this.$refs.previewFileInput.value = ''

                                }

                                let vendor = _.cloneDeep(this.storeVendors[tempIndex])

                                this.previewForm = this.storeVendors[tempIndex]
                                this.previewForm.index = i

                                this.previewForm.image_src = null
                                this.previewForm.image = vendor.image

                                this.checkedItems = [];

                                this.vendorIsSaving = false;

                                this.tempPreviewVendor = _.cloneDeep(vendor)

                            }else{

                                this.resetImageValidations();

                                if(this.$refs.previewFileInput){

                                    this.$refs.previewFileInput.value = ''

                                }

                                let vendor = listedVendor

                                this.previewForm = vendor
                                this.previewForm.index = i

                                this.previewForm.image_src = null
                                this.previewForm.image = vendor.image

                                this.checkedItems = [];

                                this.vendorIsSaving = false;

                                this.tempPreviewVendor = _.cloneDeep(vendor)

                            }

                        }

                    }

                }

            }else{

                if(!this.tourIsActive){
                
                    if(listedVendor.id != -1){

                        if(this.previewForm.id == -1){

                            MessageBox.confirm(
                                "Are you sure? unsaved changes will be discarded", 
                                "Warning", 
                                {
                                    confirmButtonText: "Yes",
                                    cancelButtonText: "Cancel",
                                    type: "warning",
                                }
                            ).then(()=>{

                                let index = this.vendorsList.findIndex((vendor) => vendor.id == -1);

                                if(index != -1){

                                    this.vendorsList.splice(index,1); 

                                }

                                if(!this.vendorSaved){

                                    let tempIndex = this.vendorsList.findIndex(x=>x.id == this.tempPreviewVendor.id);

                                    if(tempIndex != -1){

                                        this.vendorsList[tempIndex] = this.tempPreviewVendor

                                    }

                                }

                                this.resetImageValidations();

                                if(this.$refs.previewFileInput){

                                    this.$refs.previewFileInput.value = ''

                                }

                                let vendor = listedVendor

                                this.previewForm = vendor
                                this.previewForm.index = i

                                this.previewForm.image_src = null
                                this.previewForm.image = vendor.image

                                this.checkedItems = [];

                                this.vendorIsSaving = false;

                                this.tempPreviewVendor = _.cloneDeep(vendor)

                            }).catch(() => {})

                        }else{

                            let index = this.vendorsList.findIndex((vendor) => vendor.id == -1);
                                
                            if(index != -1){

                                this.vendorsList.splice(index, 1);

                            }

                            if(!this.vendorSaved && (this.tempPreviewVendor.id != '' && this.tempPreviewVendor.id != listedVendor.id)){
                                
                                let tempIndex = this.vendorsList.findIndex(x=>x.id == this.tempPreviewVendor.id);

                                if(tempIndex != -1){

                                    this.vendorsList[tempIndex] = this.tempPreviewVendor;

                                }

                            }

                            if(this.tempPreviewVendor.id != '' && this.tempPreviewVendor.id == listedVendor.id){

                                let tempIndex = this.vendorsList.findIndex(x=>x.id == this.tempPreviewVendor.id);
                                
                                this.vendorsList[tempIndex] = _.cloneDeep(this.storeVendors[tempIndex]);

                                this.resetImageValidations();

                                if(this.$refs.previewFileInput){

                                    this.$refs.previewFileInput.value = ''

                                }

                                let vendor = _.cloneDeep(this.storeVendors[tempIndex])

                                this.previewForm = this.storeVendors[tempIndex]
                                this.previewForm.index = i

                                this.previewForm.image_src = null
                                this.previewForm.image = vendor.image

                                this.checkedItems = [];

                                this.vendorIsSaving = false;

                                this.tempPreviewVendor = _.cloneDeep(vendor)

                            }else{

                                this.resetImageValidations();

                                if(this.$refs.previewFileInput){

                                    this.$refs.previewFileInput.value = ''

                                }

                                let vendor = listedVendor

                                this.previewForm = vendor
                                this.previewForm.index = i

                                this.previewForm.image_src = null
                                this.previewForm.image = vendor.image

                                this.checkedItems = [];

                                this.vendorIsSaving = false;

                                this.tempPreviewVendor = _.cloneDeep(vendor)

                            }

                        }

                    }
                
                }

            }

        },
        previewSearchedVendor(listedVendor,i){

            let index = this.vendorsList.findIndex((vendor) => vendor.id == -1);

            if(index != -1){

                this.vendorsList.splice(index,1); 

            }

            if(!this.vendorSaved){

                let tempIndex = this.vendorsList.findIndex(x=>x.id == this.tempPreviewVendor.id);

                if(tempIndex != -1){

                    this.vendorsList[tempIndex] = this.tempPreviewVendor

                }

            }

            this.resetImageValidations();

            if(this.$refs.previewFileInput){

                this.$refs.previewFileInput.value = ''

            }

            let vendor = listedVendor

            this.previewForm = vendor
            this.previewForm.index = i

            this.previewForm.image_src = null
            this.previewForm.image = vendor.image

            this.checkedItems = [];

            this.vendorIsSaving = false;

            this.tempPreviewVendor = _.cloneDeep(vendor)

        },
        closePreview(){

            if(this.previewForm.isTouched){

                MessageBox.confirm(
                    "Do you want to close? Entered information will be discarded.", 
                    "Warning", 
                    {
                        confirmButtonText: "Yes",
                        cancelButtonText: "Cancel",
                        type: "warning",
                    }
                ).then(async () => {

                    let index = this.vendorsList.findIndex((vendor) => vendor.id == -1);

                    if(index != -1){

                        this.vendorsList.splice(index,1); 

                    }

                    if(!this.vendorSaved){

                        let tempIndex = this.vendorsList.findIndex(x=>x.id == this.tempPreviewVendor.id);

                        if(tempIndex != -1){

                            this.vendorsList[tempIndex] = this.tempPreviewVendor;

                        }

                    }

                    this.previewForm.id = ''

                    // this.previewForm = _.cloneDeep(this.defaultPreviewForm);

                    this.tempVendor = _.cloneDeep(this.defaultPreviewForm);

                    this.tempPreviewVendor = _.cloneDeep(this.defaultPreviewForm);

                    this.vendorSaved = false;

                    this.contactFieldTouched = false;

                    this.$refs.vendorFormObserver.reset();

                }).catch(() => {})

            }else{

                let index = this.vendorsList.findIndex((vendor) => vendor.id == -1);

                if(index != -1){

                    this.vendorsList.splice(index,1); 

                }

                if(!this.vendorSaved){

                    let tempIndex = this.vendorsList.findIndex(x=>x.id == this.tempPreviewVendor.id);

                    if(tempIndex != -1){

                        this.vendorsList[tempIndex] = this.tempPreviewVendor;

                    }

                }

                this.previewForm.id = ''

                // this.previewForm = _.cloneDeep(this.defaultPreviewForm);

                this.tempVendor = _.cloneDeep(this.defaultPreviewForm);

                this.tempPreviewVendor = _.cloneDeep(this.defaultPreviewForm);

                this.vendorSaved = false;

                this.contactFieldTouched = false;

                this.$refs.vendorFormObserver.reset();

            }

        },
        resetPreview(){

            let index = this.vendorsList.findIndex((vendor) => vendor.id == -1);

            if(index != -1){

                this.vendorsList.splice(index,1); 

            }

            this.previewForm.id = ''

            // this.previewForm = _.cloneDeep(this.defaultPreviewForm);

            this.tempVendor = _.cloneDeep(this.defaultPreviewForm);

            this.tempPreviewVendor = _.cloneDeep(this.defaultPreviewForm);

            this.vendorSaved = false;

            this.contactFieldTouched = false;

            this.$refs.vendorFormObserver.reset();

        },
        createVendor(){

            if(this.vendorsLoading){

                this.$notify({
                    type: "info",
                    title: "Please Wait",
                    message: "Vendors are being retrieved.",
                });

                return

            }

            if(this.vendorIsSaving){

                this.$notify({
                    type: "info",
                    title: "Please Wait",
                    message: "A vendor is currently being processed.",
                });

                return

            }

            let index = this.vendorsList.findIndex(x=>x.id == -1);

            if(index != -1){

                // this.$notify({
                //   type: "error",
                //   title: "Can't Add New Vendor",
                //   message: "you have an unsaved vendor in the list",
                // });

                return
            }

            this.closePreview();

            this.resetImageValidations();

            this.previewForm = _.cloneDeep(this.defaultPreviewForm);

            this.previewForm.id = -1;

            let tempVendor = this.previewForm;

            this.vendorsList.unshift(tempVendor);

            this.checkedItems = [];

            this.$refs.vendorFormObserver.reset();

        },
        //preview form open/close & reset ends

        //add/update & delete vendor start
        validate(){

            this.$refs.vendorFormObserver.validate().then(success => {

                if(!success){

                    const errors = Object.entries(this.$refs.vendorFormObserver.errors)
                    .map(([key, value]) => ({ key, value })).filter(error => error["value"].length);

                    this.$refs.vendorFormObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center" });

                    if(typeof this.selectedContactObj.valid == 'undefined' || !this.selectedContactObj.valid){

                        this.contactIsValid = false;
                        this.contactFieldTouched = true;

                        document.querySelector("#contact-field").scrollIntoView({ behavior: 'smooth',block: "center" });

                    }

                    if(errors.length > 0){

                        if(this.previewForm.id != -1 && !this.previewForm.image){

                            this.imageValidations.imgIsRequired = true;

                        }else if(this.previewForm.id == -1 && !this.previewForm.addImage){

                            this.imageValidations.imgIsRequired = true;

                        }

                    }else{

                        if(!this.previewForm.image){

                            this.imageValidations.imgIsRequired = true;

                            document.querySelector("#vendor-image").scrollIntoView({ behavior: 'smooth',block: "center" });

                        }

                    }

                }else{

                    if(typeof this.selectedContactObj.valid == 'undefined' || !this.selectedContactObj.valid){

                        this.contactIsValid = false;
                        this.contactFieldTouched = true;

                    }

                    if(this.contactIsValid && this.countryIsSelected){

                        if(this.previewForm.id != -1 && !this.previewForm.image){

                            this.imageValidations.imgIsRequired = true;

                            document.querySelector("#vendor-image").scrollIntoView({ behavior: 'smooth',block: "center" });

                        }else if(this.previewForm.id == -1 && !this.previewForm.addImage){

                            this.imageValidations.imgIsRequired = true;

                            document.querySelector("#vendor-image").scrollIntoView({ behavior: 'smooth',block: "center" });

                        }else{

                            if(this.previewForm.id == -1){

                                this.addVendorHandle();

                            }else{

                                this.updateVendorHandle();

                            }

                        }

                    }else{

                        document.querySelector("#contact-field").scrollIntoView({ behavior: 'smooth',block: "center" });

                    }

                }

            });

        },
        async addVendorHandle(){

            let formData = new FormData();

            formData.append('company_name',this.previewForm.company_name);
            formData.append('name',this.previewForm.name.trim());
            formData.append('email',this.previewForm.email.trim());
            formData.append('contact_no',this.previewForm.contact_no.replace(/\s/g,''));
            formData.append('address',this.previewForm.address);
            formData.append('city',this.previewForm.city);

            if(this.previewForm.addImage){

                formData.append('image',this.previewForm.addImage);

            }

            this.vendorIsSaving = true;
            try{
                let res = await this.$axios.post('/vendor/add',formData);
                if(res.data.status_code == "1047"){

                    this.$notify({
                        type: 'success',
                        title: 'Success',
                        message: res.data.message,
                    });

                    this.vendorSaved = true;

                    this.resetPreview();

                    this.$store.commit('vendor_module/add_vendors_list',res.data.vendor);

                    this.$store.commit('gallery_module/CLEAR_MODULE');

                    this.$store.commit('expenses_module/CLEAR_MODULE');

                    this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

                }
            }catch(error){

                if(error.response){

                    if(error.response.data.error.email){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error.email[0],
                        });

                    }else if(error.response.data.error.includes('storage limit has been reached')){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            dangerouslyUseHTMLString: true,
                            message: error.response.data.error,
                        });

                    }else if(error.response.data.error.includes("doesn't support")){

                        let subscriptionPath = window.location.origin + "/subscription";

                        this.$message({
                            type: 'error',
                            showClose: true,
                            dangerouslyUseHTMLString: true,
                            message: error.response.data.error 
                            + ` <a href='${subscriptionPath}' target='_blank'>Click here</a> to Upgrade your plan.`,
                        });

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.message,
                        });

                    }

                }else{

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.message,
                    });

                }

            }finally{

                this.vendorIsSaving = false;

            }

        },
        async updateVendorHandle(){
            
            let formData = new FormData();

            formData.append('id',this.previewForm.id);
            formData.append('company_name',this.previewForm.company_name);
            formData.append('name',this.previewForm.name.trim());
            formData.append('email',this.previewForm.email.trim());
            formData.append('contact_no',this.previewForm.contact_no.replace(/\s/g,''));
            formData.append('address',this.previewForm.address);
            formData.append('city',this.previewForm.city);

            if(this.previewForm.image_src){

                formData.append('image',this.previewForm.image);

            }

            this.vendorIsSaving = true;
            try{
                let res = await this.$axios.post('/vendor/update',formData);
                if(res.data.status_code == "1048"){

                    this.$notify({
                        type: 'success',
                        title: 'Success',
                        message: res.data.message,
                    });

                    let vendorIndex = this.vendorsList.findIndex((vendor) => vendor.id == res.data.vendor.id);

                    let payload = {
                        vendor: res.data.vendor,
                        index: vendorIndex
                    }

                    this.vendorsList[vendorIndex] = res.data.vendor;

                    this.$store.commit('vendor_module/update_vendors_list',payload);

                    this.$store.commit('gallery_module/CLEAR_MODULE');

                    this.$store.commit('expenses_module/CLEAR_MODULE');

                    this.$store.commit('bills_module/CLEAR_MODULE');

                    this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

                    if(this.previewForm.id == res.data.vendor.id){

                        this.vendorSaved = true;

                        this.resetPreview();

                    }

                }
            }catch(error){

                if(error.response){

                    if(error.response.data.error.id){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error.id[0],
                        });

                    }else if(error.response.data.error.email){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error.email[0],
                        });

                    }else if(error.response.data.error.includes('storage limit has been reached')){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            dangerouslyUseHTMLString: true,
                            message: error.response.data.error,
                        });

                    }else if(error.response.data.error.includes("doesn't support")){

                        let subscriptionPath = window.location.origin + "/subscription";

                        this.$message({
                            type: 'error',
                            showClose: true,
                            dangerouslyUseHTMLString: true,
                            message: error.response.data.error 
                            + ` <a href='${subscriptionPath}' target='_blank'>Click here</a> to Upgrade your plan.`,
                        });

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.message,
                        });

                    }

                }else{

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.message,
                    });

                }

            }finally{

                this.vendorIsSaving = false;

            }

        },
        deleteVendorHandle(listedVendor,index){

            if(!this.tourIsActive){
            
                MessageBox.confirm(
                    "Are you sure you want to delete the vendor?", 
                    "Warning", 
                    {
                        confirmButtonText: "Yes",
                        cancelButtonText: "Cancel",
                        type: "warning",
                    }
                ).then(async () => {

                    this.deletingItems.push(listedVendor.id);
                    this.deleteLoader = true;

                    let loader = Loading.service({
                        text: "The vendor is being removed. Please wait!",
                        fullscreen: true,
                    });

                    try{
                        let res = await this.$axios.delete(`/vendor/remove/${listedVendor.id}`);
                        if(res.data.status_code == "1049"){

                            this.$notify({
                                type: 'success',
                                title: 'Success',
                                message: res.data.message,
                            })

                            // this.$store.commit('vendor_module/delete_vendors_list',index);

                            this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

                            this.$store.commit('gallery_module/CLEAR_MODULE');

                            this.$store.commit('expenses_module/CLEAR_MODULE');

                            //removing deleted item from checked items list
                            this.checkedItems = this.checkedItems.filter((item) => {

                                if(item != listedVendor.id){
                                    
                                    return item;
                                    
                                }

                            });

                            this.vendorsLoading = true;

                            this.$store.dispatch('vendor_module/fetchStoreVendorsRequest',{size:10, page:1, loadData: 'new'});

                        }
                    }catch(error){

                        if(error.response){

                            if(error.response.data.error.vendor_id){

                                this.$message({
                                    type: 'error',
                                    showClose: true,
                                    message: error.response.data.error.vendor_id[0],
                                });

                            }else if(error.response.data.error.includes("doesn't support")){

                                let subscriptionPath = window.location.origin + "/subscription";

                                this.$message({
                                    type: 'error',
                                    showClose: true,
                                    dangerouslyUseHTMLString: true,
                                    message: error.response.data.error 
                                    + ` <a href='${subscriptionPath}' target='_blank'>Click here</a> to Upgrade your plan.`,
                                });

                            }else{

                                this.$message({
                                    type: 'error',
                                    showClose: true,
                                    message: error.response.data.message,
                                });

                            }

                        }else{

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.message,
                            });

                        }

                    }finally{

                        loader.close();

                        this.deletingItems = [];
                        this.deleteLoader = false;

                    }

                }).catch(() => {})
            
            }

        },
        //add/update & delete vendor end

        //bulk action start
        vendorsBulkActionHandle(action){

            this.resetPreview();

            MessageBox.confirm(
                'Do you really want to make the changes?',
                'Confirm',
                {
                    distinguishCancelAndClose: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                }
            ).then(async ()=>{

                if(action == 'delete'){
                
                    let formData = new FormData();

                    formData.append('resource_type', 'vendor');
                    formData.append("resource_ids", JSON.stringify(this.checkedItems));
                    formData.append("action", action);

                    this.deletingItems = _.cloneDeep(this.checkedItems)
                    this.deleteLoader = true;
                    
                    let loader = Loading.service({
                        text: "The selected vendors are being removed. Please wait!",
                        fullscreen: true,
                    });

                    try{
                        let res = await this.$axios.post('/bulk-action',formData);
                        if(res.data.status_code == "2031"){

                            this.$notify({
                                type: 'success',
                                title: 'Success',
                                message: 'Vendors have been successfully removed.',
                            });

                            // let payload = {
                            //     action: 'delete',
                            //     listIds: this.checkedItems
                            // }

                            // this.$store.commit('vendor_module/vendors_bulk_action',payload);

                            this.vendorsLoading = true;

                            this.$store.dispatch('vendor_module/fetchStoreVendorsRequest',{size:10, page:1, loadData: 'new'});

                            this.checkedItems = [];
                            this.deletingItems = [];
                            this.deleteLoader = false;

                        }
                    }catch(error){

                        this.deletingItems = [];
                        this.deleteLoader = false;

                        if(error.response.data.error.includes("doesn't support")){

                            let subscriptionPath = window.location.origin + "/subscription";

                            this.$message({
                                type: 'error',
                                showClose: true,
                                dangerouslyUseHTMLString: true,
                                message: error.response.data.error 
                                + ` <a href='${subscriptionPath}' target='_blank'>Click here</a> to Upgrade your plan.`,
                            });

                        }else if(error.response.data.message){

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.response.data.message,
                            });

                        }else{

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: "One of the vendors may have been deleted or isn't available.",
                            });

                        }

                    }finally{

                        loader.close();

                    }

                }

            }).catch(() => {})

        },
        //bulk action end

        //search vendor start
        searchVendor(keyword){

            this.$refs.module_search.searchedKeyword = keyword
            
            this.$refs.module_search.search();

        },
        clearSearch(){

            this.searchedItem = ''

            this.searchVendor();

        },
        //search vendor end

        //contact no. validation methods start
        setMobileNoFieldValidations(validations){

            this.contactIsValid = validations.isContactValid
            this.countryIsSelected = validations.isCountrySelected
            this.contactFieldTouched = validations.isFieldTouched
            this.selectedContactObj = validations.contactObj

            this.previewForm.contact_no = validations.addContactNo

        },
        //contact no. validation methods end

        //other methods start
        async changeAddImage(e){

            this.imageValidations = {
                imgIsRequired:false,
                imgInvalidFormat:false,
                isNotImg:false,
            }

            if(e.target.files.length > 0){

                const file = e.target.files[0];

                if(file.name.length > 50){

                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: "Uploaded file name must not exceed 50 characters.",
                    }); 

                    this.imageValidations.imgIsRequired = false;

                    if(this.$refs.addPreviewFileInput){

                        this.$refs.addPreviewFileInput.value = ''

                    }

                    return

                }

                if(file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/webp' || file.type == 'image/png'){
                    
                    this.previewForm.addImage = file;
                    this.previewForm.addImage_src = URL.createObjectURL(file);

                    this.imageValidations.imgIsRequired = false;

                    if(this.$refs.addPreviewFileInput){

                        this.$refs.addPreviewFileInput.value = ''

                    }

                }else if(file.type.includes('image/')){

                    let uploadedFileType = file.type.slice(6);
                    
                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: `${uploadedFileType.toUpperCase()} is not a valid file type.`,
                    });

                    if(!this.previewForm.addImage){

                        this.imageValidations.imgIsRequired = true;

                    }
                    
                    // this.imageValidations.imgInvalidFormat = true

                }else{

                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: "Uploaded file must be an image.",
                    }); 

                    if(!this.previewForm.addImage){

                        this.imageValidations.imgIsRequired = true;

                    }

                    // this.imageValidations.isNotImg = true

                }

            }

        },
        async handleChangeEditImage(e){

            this.imageValidations = {
                imgIsRequired:false,
                imgInvalidFormat:false,
                isNotImg:false,
            }

            if(e.target.files.length > 0){

                const file = e.target.files[0];

                if(file.name.length > 50){

                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: "Uploaded file name must not exceed 50 characters.",
                    }); 

                    this.imageValidations.imgIsRequired = false;

                    if(this.$refs.previewFileInput){

                        this.$refs.previewFileInput.value = ''

                    }

                    return

                }

                if(file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/webp' || file.type == 'image/png'){
                
                    this.previewForm.image = file;
                    this.previewForm.image_src = URL.createObjectURL(file);

                    this.imageValidations.imgIsRequired = false;

                    if(this.$refs.previewFileInput){

                        this.$refs.previewFileInput.value = ''

                    }

                }else if(file.type.includes('image/')){

                    let uploadedFileType = file.type.slice(6);
                    
                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: `${uploadedFileType.toUpperCase()} is not a valid file type.`,
                    });

                    if(!this.previewForm.image){

                        this.imageValidations.imgIsRequired = true;

                    }
                    
                    // this.imageValidations.imgInvalidFormat = true

                }else{

                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: "Uploaded file must be an image.",
                    }); 

                    if(!this.previewForm.image){

                        this.imageValidations.imgIsRequired = true;

                    }

                    // this.imageValidations.isNotImg = true

                }

            }

        },
        resetImageValidations(){
            
            this.imageValidations = {
                imgIsRequired:false,
                imgInvalidFormat:false,
                isNotImg:false,
            }

        },
        generateUrl(listedVendor){

            if(listedVendor.image){

                if(typeof(listedVendor.image) == "string"){

                    return this.base_image_url + this.settings.store_id + '/vendor/' + listedVendor.image;

                }else{

                    return listedVendor.image_src;

                }

            }

        },
        isDeletingItem(id){

            let find = this.deletingItems.find(x=>x == id);

            if(find){

                return true;

            }else{

                return false;

            }

        },
        checkedItemHandle(vendorId){

            if(!this.tourIsActive && vendorId != -1 && !this.previewForm.id){

                let index = this.checkedItems.findIndex(item => item == vendorId);

                if(index != -1){

                    this.checkedItems.splice(index,1);

                }else{

                    this.checkedItems.push(vendorId);

                }

            }

        },
        toggleIsCheckedAll(){

            if(!this.tourIsActive && !this.previewForm.id){

                if((this.checkedItems.length == this.vendorsList.length) ||
                (this.checkedItems.length == this.vendorsList.length)){

                    this.checkedItems = [];

                }else if(this.checkedItems.length < this.vendorsList.length){

                    this.checkedItems = [];
                    
                    this.vendorsList.forEach(vendor => {

                        if(vendor.id != -1){

                            this.checkedItems.push(vendor.id);

                        }

                    });

                }

            }

        },
        clearSelection(){

            MessageBox.confirm(
                'Do you really want to uncheck the selected records?',
                'Confirm',
                {
                    distinguishCancelAndClose: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                }
            ).then(async () => {

                this.checkedItems = [];

            }).catch(() => {})

        },
        expand(){

            this.$refs.viewInputForm.classList.toggle('fullWidthSlide');

        },
        vendorsKeyEvent(e){

            if(e.key == 'Escape'){

                if(this.$refs.viewInputForm.classList.contains('fullWidthSlide')){

                    this.$refs.viewInputForm.classList.remove('fullWidthSlide');

                }else if(this.$refs.viewInputForm.classList.contains('activeRow')){

                    this.closePreview();

                }

            }

        },
        //other methods ends

        //tour methods start
        startVendorsTour(){

            this.$tours.vendorsTour.start();

            this.tourIsActive = true;

            if(this.previewForm.id != ''){

                this.resetPreview();

            }

        },
        handleNextStep(currentStep){

            if(currentStep + 1 == 7){

                document.querySelector(".tableActionBtns").style.position = "static";

                document.querySelector(".productSlideBox").style.overflowY  = "hidden";

                this.createVendor();

            }

            if(currentStep >= 15){

                document.querySelector(".tableActionBtns").style.position = "sticky";

            }

            if(currentStep + 1 == 19){

                if(!this.$refs.viewInputForm.classList.contains('fullWidthSlide')){

                    this.expand();

                }

            }

            if(currentStep + 1 == 20){

                this.expand();

            }

        },
        handlePreviousStep(currentStep){

            if(currentStep == 7){

                this.resetPreview();

                document.querySelector(".tableActionBtns").style.position = "sticky";

                document.querySelector(".productSlideBox").style.overflowY  = "auto";

            }

            if(currentStep <= 16){

                document.querySelector(".tableActionBtns").style.position = "static";

            }

            if(currentStep == 19){

                if(this.$refs.viewInputForm.classList.contains('fullWidthSlide')){

                    this.expand();

                }

            }

            if(currentStep == 20){

                if(!this.$refs.viewInputForm.classList.contains('fullWidthSlide')){

                    this.expand();

                }

            }

        },
        handleTourStop(){

            document.querySelector(".tableActionBtns").style.position = "sticky"

            document.querySelector(".productSlideBox").style.overflowY  = "auto"

            this.resetPreview();

            this.tourIsActive = false;

        },
        handleTourFinish(){

            document.querySelector(".tableActionBtns").style.position = "sticky"

            document.querySelector(".productSlideBox").style.overflowY  = "auto"

            this.resetPreview();

            this.tourIsActive = false;

        },
        handleTourSkip(){

            document.querySelector(".tableActionBtns").style.position = "sticky"

            document.querySelector(".productSlideBox").style.overflowY  = "auto"

            this.resetPreview();

            this.tourIsActive = false;

        },
        //tour methods end

        //image loading error handling
        handleImageError(e){

            e.target.src = '/images/image_not_found.png'

        },

    },
    mounted(){

        this.$refs.vendorsTable.addEventListener('scroll', this.handleScrollEvent);

        window.addEventListener('keydown',this.vendorsKeyEvent);

        window.addEventListener('beforeunload', (event) => {

            let index = null;

            this.vendorsList.forEach((vendor) => {

                if(vendor.id == -1){

                    index = this.vendorsList.indexOf(vendor);

                }

            });

            if(index != null){

                event.returnValue = "Are you sure you want to leave? entered information will be lost";

            }

        });

        //master search
        if(this.$route.query.search){
      
            this.$refs.module_search.searchedKeyword = this.$route.query.search

            if(this.$route.query.preview == 1){

                this.isMasterSearched = true

            }

            this.$router.replace({'query': null});

            this.$refs.module_search.search();

        }

    },
    async beforeMount(){

        this.vendorsLoading = true;
        try{

            if((!this.storeVendors || this.storeVendors.length == 0) && this.vendorSearchKeyword.length == 0){
            
                this.$store.dispatch('vendor_module/fetchStoreVendorsRequest',{size:10, page:1, loadData: 'new'});

            }else{

                this.vendorsList = _.cloneDeep(this.storeVendors);
                this.vendorsLoading = false;

            }

            if(this.vendorSearchKeyword.length > 0){

                this.searchedItem = this.vendorSearchKeyword

            }

        }catch(error){

            this.vendorsLoading = false;

            this.$message({
                type: "error",
                showClose: true,
                message: error.response ? error.response.data.message : error.message,
            });

        }

    },
    beforeDestroy(){

        window.removeEventListener('keydown', this.vendorsKeyEvent);

    },
    beforeRouteLeave(to, from, next){

        let index = null;

        this.vendorsList.forEach((vendor) => {

            if(vendor.id == -1){

                index = this.vendorsList.indexOf(vendor);

            }

        });

        if(window.innerWidth <= 1024){

            document.body.classList.remove('activeIpad');
            document.body.classList.add('hideSideBar');

        }

        if((this.previewForm.id || index != null) && to.fullPath != '/login'){

            MessageBox.confirm(
                'You may have unsaved changes, Proceeding will discard them',
                'Confirm',
                {
                    distinguishCancelAndClose: true,
                    confirmButtonText: 'Process',
                    cancelButtonText: 'Stay',
                }
            ).then(()=>{

                this.resetPreview();

                next();

            }).catch(()=>{})

        }else{

            next();

        }

    },
}
window.myFunction = function (event) {
    // reset all menu items
    document.querySelectorAll(".sectionTabsLink ul li a.active").forEach(function (item) {
        item.classList.remove("active");
    });
    // mark as active selected menu item
    event.target.classList.add("active");
};
</script>

<style>

</style>